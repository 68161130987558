"use client";
import { ApiError } from "@llamaindex/cloud/api";
import { redirect } from "next/navigation";
import React from "react";
import { FallbackProps } from "react-error-boundary";

export const NotAuthenticatedErrorBoundary: React.FC<FallbackProps> = (
  props: FallbackProps,
) => {
  const { error } = props;
  if (
    error instanceof ApiError &&
    (error.status === 403 || error.status === 401)
  ) {
    redirect("/login");
  } else {
    throw error;
  }
};
