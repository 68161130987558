import { Crisp } from "crisp-sdk-web";

import { User } from "@/hooks/auth";

const CRISP_ENV = process?.env?.NEXT_PUBLIC_CRISP_APP_ID;

export const initializeCrisp = (user: User) => {
  if (!user) return;

  if (!CRISP_ENV) return;

  Crisp.configure(CRISP_ENV, {
    autoload: false,
  });

  if (user.email) {
    Crisp.user.setEmail(user.email);
  }

  if (user.displayName) {
    Crisp.user.setNickname(user.displayName);
  }

  if (user.photoURL) {
    Crisp.user.setAvatar(user.photoURL);
  }

  Crisp.load();
};
