import { create } from "zustand";
import { combine, persist, subscribeWithSelector } from "zustand/middleware";

type PlaygroundState = {
  openChatSettings: boolean;
  openPlaygroundSettings: boolean;
  openFileRetrievalSettings: boolean;
  openMetadataFilterConfig: boolean;
  chatModelElement: HTMLDivElement | null;
};

type PlaygroundActions = {
  setOpenChatSettings(open: boolean): void;
  setCurrentChatModelElement(element: HTMLDivElement | null): void;
  highlightChatModel(): void;
  setOpenPlaygroundSettings(open: boolean): void;
  setOpenFileRetrievalSettings(open: boolean): void;
  setOpenMetadataFilterConfig(open: boolean): void;
};

type AppState = PlaygroundState & {
  projectId: string | null;
  isCollapsed: boolean;
  panelSizes: number[];
  organizationId: string | null;
  loading: boolean;
  error: Error | null;
};

type AppAction = PlaygroundActions & {
  setPanelSizes(sizes: number[]): void;
  setProjectId(projectId: string | null): void;
  setOrganizationId(organizationId: string): void; // Add this line
  setIsCollapsed(isCollapsed?: boolean): void;
};

export const useAppStore = create(
  subscribeWithSelector(
    persist(
      combine<AppState, AppAction>(
        {
          openChatSettings: false,
          openPlaygroundSettings: true,
          openFileRetrievalSettings: true,
          openMetadataFilterConfig: false,
          chatModelElement: null,

          panelSizes: [33, 67],
          projectId: null,
          isCollapsed: false,
          organizationId: null,
          loading: false,
          error: null,
        },
        (set, get, store) => ({
          setOpenChatSettings(open) {
            set({ openChatSettings: open });
          },
          setCurrentChatModelElement(element: HTMLDivElement) {
            set({ chatModelElement: element });
          },
          setOpenPlaygroundSettings(open) {
            set({ openPlaygroundSettings: open });
          },
          setOpenFileRetrievalSettings(open) {
            set({ openFileRetrievalSettings: open });
          },
          highlightChatModel() {
            set({ openChatSettings: true });
            import("driver.js")
              .then(({ driver }) => {
                const { chatModelElement } = get();
                if (!chatModelElement) return;
                driver().highlight({
                  element: chatModelElement,
                  popover: {
                    title: "Multi-modal Models",
                    description:
                      "Select a multi-modal model to enable Multi-modal retrieval",
                  },
                });
              })
              .catch(() => {
                console.error("Failed to load driver.js");
              });
          },
          setOpenMetadataFilterConfig(open) {
            set({ openMetadataFilterConfig: open });
          },
          setPanelSizes(sizes) {
            set({
              panelSizes: sizes,
            });
          },
          setProjectId(projectId: string) {
            set({ projectId });
          },
          setIsCollapsed(isCollapsed?: boolean) {
            set((value) => ({ isCollapsed: isCollapsed ?? value.isCollapsed }));
          },
          setOrganizationId(organizationId: string) {
            set({ organizationId });
          },
        }),
      ),
      {
        name: "app-store",
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              [
                "projectId",
                "isCollapsed",
                "panelSizes",
                "openChatSettings",
                "openPlaygroundSettings",
                "openFileRetrievalSettings",
                "openMetadataFilterConfig",
                "organizationId",
                "role",
                "permissions",
              ].includes(key),
            ),
          ),
      },
    ),
  ),
);
