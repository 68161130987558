import { Loader2 } from "lucide-react";

export type LoadingProps = {
  message?: string;
};

function Loading({ message }: LoadingProps) {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="size-6 animate-spin rounded-full border-4 border-dashed border-blue-500"></div>
      <p className="ml-2 text-gray-600">{message ?? "Loading..."}</p>
    </div>
  ); // loading component
}

type LoadingStatusProps = {
  message?: string;
};
const LoadingStatus = (props: LoadingStatusProps) => (
  <div className="flex flex-row items-center space-x-2">
    <Loader2 className="size-5 animate-spin stroke-slate-500" />
    <div className="flex flex-col text-sm text-slate-500">
      <div>{props.message ?? "Loading"}</div>
    </div>
  </div>
);

export { Loading, LoadingStatus };
