"use client";
import { toast } from "@llamaindex/component/ui/use-toast";
import { OAuthProvider, linkWithCredential } from "firebase/auth";

import { FirebaseError } from "firebase/app";

import firebase from "firebase/compat/app";
import { googleAuth } from '@/lib/runtime'

export enum LoginProvider {
  GOOGLE = "google",
  GITHUB = "github",
  MICROSOFT = "microsoft",
  EMAIL = "email",
}

export const DEFAULT_LOGIN_PROVIDER = LoginProvider.EMAIL;

export const providerMap: Record<string, LoginProvider> = {
  "google.com": LoginProvider.GOOGLE,
  "github.com": LoginProvider.GITHUB,
  "microsoft.com": LoginProvider.MICROSOFT,
  password: LoginProvider.EMAIL,
};

export async function handleRedirectResult(
  result: firebase.auth.UserCredential,
): Promise<boolean> {
  if (!googleAuth) return false;

  try {
    if (!result.user) {
      console.error("No user in redirect result", result);
      return false;
    }

    const token = await result.user.getIdToken();

    const res = await fetch("/api/login", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      console.error("Error logging in", res.status);
      return false;
    }

    const pendingCred = sessionStorage.getItem("pendingCred");
    const pendingEmail = sessionStorage.getItem("pendingEmail");

    if (pendingCred && pendingEmail) {
      sessionStorage.removeItem("pendingCred");
      sessionStorage.removeItem("pendingEmail");

      const credential = OAuthProvider.credentialFromJSON(pendingCred);

      if (result.user.email === pendingEmail) {
        await linkWithCredential(result.user, credential);
      }
    }

    localStorage.removeItem("lastLoginProvider");
    const provider = providerMap[result.additionalUserInfo?.providerId ?? ""];
    if (provider) {
      localStorage.setItem("lastLoginProvider", provider);
    }

    return true;
  } catch (error) {
    handleLoginError(error);
    return false;
  }
}

export function handleLoginError(error: any) {
  if (!(error instanceof FirebaseError)) {
    console.error("Error signing in with OAuth", error);
  }

  if (error.code === "auth/account-exists-with-different-credential") {
    toast("Account Already Exists", {
      description:
        "Sign in with a previously used provider. We'll automatically link it to your new method.",
    });

    const pendingCred = OAuthProvider.credentialFromError(error);
    const pendingEmail = error.customData?.email as string;

    if (!pendingCred) return;

    // using sessionStorage instead of localStorage because we only want this to persist for the current session
    sessionStorage.setItem("pendingError", JSON.stringify(error));
    sessionStorage.setItem("pendingCred", JSON.stringify(pendingCred));
    sessionStorage.setItem("pendingEmail", pendingEmail);

    return;
  }

  if (error.message.includes("User is not whitelisted")) {
    toast("Access Not Yet Granted", {
      description:
        "You've been added to the waitlist. Contact platform@llamaindex.ai for support.",
      duration: 10000,
    });
    return;
  }

  console.error("Error handling redirect result", error);
}
