"use client";

import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

interface ToolTipperProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  delayDuration?: number;
  side?: "top" | "bottom" | "left" | "right";
}

export function ToolTipper({
  content,
  children,
  delayDuration,
  side,
}: ToolTipperProps) {
  return (
    <Tooltip delayDuration={delayDuration}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side={side}>
        <p>{content}</p>
      </TooltipContent>
    </Tooltip>
  );
}

export default ToolTipper;
