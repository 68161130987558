export function getAllowed(decodedClaims: any) {
  if (process.env.NEXT_PUBLIC_AUTH_DISABLED === "true") {
    return {
      index: true,
      eval: true,
      extraction: true,
      apiDatasourceAccess: false,
    };
  }

  const allowed = {
    index:
      !!decodedClaims?.customClaims?.allowedPlayground ||
      !!decodedClaims?.customClaims?.allowedIndex ||
      !!decodedClaims?.claims?.allowed_index ||
      !!decodedClaims?.claims?.allowed_playground,
    eval: !!decodedClaims?.customClaims?.allowedEval,
    // extraction: !!decodedClaims?.customClaims?.allowedExtraction,
    extraction: false,
    apiDatasourceAccess:
      !!decodedClaims?.customClaims?.apiDatasourceAccess ||
      !!decodedClaims?.claims?.api_datasource_access,
  };

  return allowed;
}

export function getParsingGrant(decodedClaims: any) {
  if (process.env.NEXT_PUBLIC_AUTH_DISABLED === "true") {
    return 10;
  }

  return decodedClaims?.customClaims?.parsingGrant;
}

export function isParsePremium(decodedClaims: any) {
  if (process.env.NEXT_PUBLIC_AUTH_DISABLED === "true") {
    return true;
  }

  return (
    !!decodedClaims?.customClaims?.parsePremium ||
    !!decodedClaims?.claims?.parse_premium
  );
}
