"use client";
import { createContext, PropsWithChildren, ReactNode, use } from "react";

type BaseLayoutContext = {
  serverNavigationItems: ReactNode;
  serverHeaderItems: ReactNode;
};

const BaseLayoutContext = createContext<BaseLayoutContext>(null!);

type BaseLayoutProviderProps = PropsWithChildren<{
  value: BaseLayoutContext;
}>;
export const BaseLayoutProvider = (props: BaseLayoutProviderProps) => {
  return <BaseLayoutContext.Provider {...props} />;
};
export const useBaseLayout = () => use(BaseLayoutContext);
