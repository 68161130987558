"use client";
import ToolTipper from "@llamaindex/component/ui/tooltipper";
import { Book } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMemo } from "react";

type DynamicDocumentLinkProps = {
  isCollapsed?: boolean;
};

export const DynamicDocumentLink = ({
  isCollapsed,
}: DynamicDocumentLinkProps) => {
  const pathname = usePathname();
  const url = useMemo(() => {
    if (pathname === "/parse") {
      return "https://docs.cloud.llamaindex.ai/category/API/parsing";
    }
    return "https://docs.cloud.llamaindex.ai";
  }, [pathname]);
  return (
    <>
      {isCollapsed ? (
        <ToolTipper content="Documentation" side={"right"}>
          <Link href={url}>
            <div
              className={
                "flex cursor-pointer items-center gap-2 rounded-md text-sm transition-all duration-200"
              }
            >
              <Book className="inline size-4" />
            </div>
          </Link>
        </ToolTipper>
      ) : (
        <Link href={url}>
          <div
            className={
              "flex cursor-pointer items-center gap-2 rounded-md text-sm transition-all duration-200 hover:text-slate-600"
            }
          >
            <Book className="inline size-4" /> Documentation
          </div>
        </Link>
      )}
    </>
  );
};
